const questions1 = [
  {
    number: 1,
    text: 'I am given a real opportunity to improve my skills in the organization.',
    name: 'workExperience1',
  },
  {
    number: 2,
    text: 'I have enough information to do my job well.',
    name: 'workExperience2',
  },
  {
    number: 3,
    text: 'I feel encouraged to come up with new and better ways of doing things.',
    name: 'workExperience3',
  },
  {
    number: 4,
    text: 'When needed, I am willing to put in the extra effort to get a job done. ',
    name: 'workExperience4',
  },
  {
    number: 5,
    text: 'My workload is reasonable.',
    name: 'workExperience5',
  },
  {
    number: 6,
    text: 'My skills and abilities are effectively utilized in the workplace.',
    name: 'workExperience6',
  },
  {
    number: 7,
    text: 'The physical conditions in the workplace (e.g., noise level, temperature, lighting, cleanliness) allow employees to perform their jobs effectively.',
    name: 'workExperience7',
  },
  {
    number: 8,
    text: 'My performance appraisal is a fair reflection of my performance',
    name: 'workExperience8',
  },
  {
    number: 9,
    text: 'I am clear about my job responsibilities. ',
    name: 'workExperience9',
  },
  {
    number: 10,
    text: 'Performance evaluation process helps me improve my job performance.',
    name: 'workExperience10',
  },
  {
    number: 11,
    text: 'I am determined to give my best effort at work each day.',
    name: 'workExperience11',
  }
];


const questions2 = [
  {
    number: 1,
    text: 'The people I work with cooperate and collaborate to get the job done.',
    name: 'myteam1',
  },
  {
    number: 2,
    text: 'Employees in my team share job knowledge with each other.',
    name: 'myteam2',
  },
  {
    number: 3,
    text: 'My team members clearly understand their roles.',
    name: 'myteam3',
  },
  {
    number: 4,
    text: 'We are able to resolve conflicts with other teams collaboratively ',
    name: 'myteam4',
  },
  {
    number: 5,
    text: 'Communication in our group is open and honest.',
    name: 'myteam5',
  },
  {
    number: 6,
    text: 'It is easy to get along with my colleagues. ',
    name: 'myteam6',
  },
  {
    number: 7,
    text: 'Team members display high levels of trust and mutual Support. ',
    name: 'myteam7',
  },
  {
    number: 8,
    text: 'I feel favoritism is not a problem in my department/team.',
    name: 'myteam8',
  },
  {
    number: 9,
    text: 'Team members treat each other with respect and Understand personal differences.',
    name: 'myteam9',
  }
];


const questions3 = [
  {
    number: 1,
    text: 'There is a spirit of teamwork in the organization.',
    name: 'theorganization1',
  },{
    number: 2,
    text: 'Team/departments communicate effectively to achieve goals.',
    name: 'theorganization2',
  },{
    number: 3,
    text: 'Creativity & innovation are rewarded.',
    name: 'theorganization3',
  },{
    number: 4,
    text: 'Organizational culture foster a comfortable and Supportive work environment.',
    name: 'theorganization4',
  },{
    number: 5,
    text: 'This organization really inspires the very best in me when it comes to job performance.',
    name: 'theorganization5',
  },{
    number: 6,
    text: 'Work structure in the company makes the best use of people experience.',
    name: 'theorganization6',
  },{
    number: 7,
    text: 'I would recommend the company as a great place to work.',
    name: 'theorganization7',
  },{
    number: 8,
    text: 'I believe there are good career opportunities for me at this company.',
    name: 'theorganization8',
  },{
    number: 9,
    text: 'There is adequate company support for my skill development.',
    name: 'theorganization9',
  }
];
const questions4 = [
  {
    number: 1,
    text: 'My team lead/supervisor provides me with constructive suggestions to improve my job performance.',
    name: 'teamlead1',
  },{
    number: 2,
    text: 'Discussions with my team lead/supervisor about my performance are worthwhile. ',
    name: 'teamlead2',
  },{
    number: 3,
    text: 'My team lead/supervisor treats me with respect.',
    name: 'teamlead3',
  },{
    number: 4,
    text: 'I have trust and confidence in my team lead/supervisor. ',
    name: 'teamlead4',
  },{
    number: 5,
    text: 'I feel my team lead/supervisor is doing a good job overall.',
    name: 'teamlead5',
  },{
    number: 6,
    text: 'My team lead/supervisor is available to me when I have questions or need help.',
    name: 'teamlead6',
  },{
    number: 7,
    text: 'My team lead/supervisor is fair and even handed in the treatment of all employees.',
    name: 'teamlead7',
  },{
    number: 8,
    text: 'My team lead/supervisor role models effective leadership (i.e., behavior that is ethical, moral, consistent, positive, just). ',
    name: 'teamlead8',
  },{
    number: 9,
    text: 'My team lead/supervisor promotes an atmosphere of teamwork.',
    name: 'teamlead9',
  },{
    number: 10,
    text: ' My team lead/supervisor provides me with actionable suggestions on what I can do to improve.',
    name: 'teamlead10',
  },{
    number: 11,
    text: ' Overall, I am satisfied with my team lead/supervisor.',
    name: 'teamlead11',
  },{
    number: 12,
    text: ' When I make a mistake, my team lead/supervisor respond constructively.',
    name: 'teamlead12',
  },{
    number: 13,
    text: ' My team lead/supervisor listen to employees" opinions when making decisions.',
    name: 'teamlead13',
  },{
    number: 14,
    text: 'My performance is improved after getting feedback from my team lead/supervisor.',
    name: 'teamlead14',
  }
];
const questions5 = [
  {
    number: 1,
    text: 'Senior Management generate high levels of motivation and commitment in the workforce.',
    name: 'seniormanagement1',
  },{
    number: 2,
    text: 'Senior Management maintain high standards of honesty and integrity.',
    name: 'seniormanagement2',
  },{
    number: 3,
    text: 'Senior management supports collaboration across work units to achieve work objectives.',
    name: 'seniormanagement3',
  },{
    number: 4,
    text: 'I have a high level of respect for my organization’s senior management.',
    name: 'seniormanagement4',
  },{
    number: 5,
    text: 'Senior management communicates a clear sense of direction for my organization.',
    name: 'seniormanagement5',
  },{
    number: 6,
    text: 'Senior Management is open to new suggestions.',
    name: 'seniormanagement6',
  },{
    number: 7,
    text: 'I am satisfied with the information I receive from management about what’s going on in the company.',
    name: 'seniormanagement7',
  },{
    number: 8,
    text: 'Senior management keeps people informed about what is happening.',
    name: 'seniormanagement8',
  },{
    number: 9,
    text: 'I have confidence in the senior management of this organization.',
    name: 'seniormanagement9',
  },{
    number: 10,
    text: 'I am able to contact Senior Management as needed.',
    name: 'seniormanagement10',
  }
];
const questions6 = [
  {
    number: 1,
    text: 'I am satisfied with the recognition I receive for doing a good job.',
    name: 'jobsatisfaction1',
  },{
    number: 2,
    text: 'I am satisfied with the current organizational policies and practices.',
    name: 'jobsatisfaction2',
  },{
    number: 3,
    text: 'I am satisfied with the compensation I receive against the work I do.',
    name: 'jobsatisfaction3',
  },{
    number: 4,
    text: 'I am satisfied with everything regarding the benefits provided by the company.',
    name: 'jobsatisfaction4',
  },{
    number: 5,
    text: 'Given everything, I am satisfied with my job.',
    name: 'jobsatisfaction5',
  },{
    number: 6,
    text: 'Overall, I am satisfied with the organization given everything.',
    name: 'jobsatisfaction6',
  },{
    number: 7,
    text: 'I am satisfied with the opportunities for professional growth.',
    name: 'jobsatisfaction7',
  }
];
const questions7 = [
  {
    number: 1,
    text: 'I am aware of how I can report any case of discrimination, harassment, or violation of company code of conduct and policies.',
    name: 'complaint1',
  },{
    number: 2,
    text: 'I can disclose case of harassment and violation of company code of conduct and other policies without fear of retaliation.',
    name: 'complaint2',
  }
];
const questions8 = [
  {
    number: 1,
    text: 'I feel I have job security.',
    name: 'intent1',
  },{
    number: 2,
    text: 'I often think about quitting my job with the company.',
    name: 'intent2',
  },{
    number: 3,
    text: 'I will probably look for a new job in the next year.',
    name: 'intent3',
  },{
    number: 4,
    text: 'I am considering leaving my job with the company.',
    name: 'intent4',
  },{
    number: 5,
    text: 'If I were completely free to choose, I would prefer to continue working for the company.',
    name: 'intent5',
  }
];
export default questions1;
export { questions2, questions3, questions4, questions5, questions6, questions7, questions8 }